export const authState = (user) => (dispatch) => {
  dispatch({
    type: "LOGIN",
    payload: { user },
  });
  return Promise.resolve(user);
};

export const clearState = () => (dispatch) => {
  dispatch({
    type: "LOGOUT",
    payload: {},
  });
  return Promise.resolve();
};
