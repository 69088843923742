import {
  getLocalStoreItem,
  setLocalStoreItem,
} from "../helpers/localStoreHandler";

//const initState = JSON.parse(getLocalStoreItem("state"));
const initState = getLocalStoreItem("state") || {};
const user = initState?.Auth?.user || {};

const initialState = {...user};

export default function (state = initialState, { type, payload = {} }) {
  switch (type) {
    case "LOGIN":
      return { ...state, ...payload.user };
    case "LOGOUT":
      return {};
    default:
      return state;
  }
}
