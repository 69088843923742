import { toast } from "react-toastify";
import axios from "axios";

const url = `${process.env.REACT_APP_BACKEND_URL}/api`;

const token = async () => {
  const tokenData = localStorage.getItem("token");
  return {
    headers: {
      Authorization: `Bearer ${tokenData}`,
    },
  };
};

const getUsersProfile = async (type = null) => {
  const header = await token();
  let filterQuery = `?filters[userRole][$eq]=${type}`;
  try {
    const response = await axios.get(`${url}/user-profiles${type !== null ? filterQuery : ''}&populate=*`, header);
    return response.data;
  } catch (error) {
    throw error;
  }
};

  const addUserProfile = async (payload) => {
    try {
      const header = await token();
      const response = await axios.post(`${url}/user-profiles`, { data: payload } , header);
      return response.data;
    } catch (err) {
      throw err;
    }
  }

const updateUserProfile = async (id, payload) => {
  try {
    const header = await token();
    const response = await axios.put(`${url}/user-profiles/${id}`, { data: payload }, header);
    return response.data;

  } catch (err) {
    toast.error(err.response.message);
    throw err;
  }
}

const deleteUserProfile = async (id) => {
  const header = await token();
  axios.delete(`${url}/user-profiles/${id}`, header)
      .then((res) => {
          if (res.status === 200) {
              console.log(res)
          }
      }).catch((err) => {
          toast.error(err.response.message);
      });
}

const getUserOwnedProfile = async () => {
  try {
    const header = await token();
    const response = await axios.get(`${url}/user-profiles/self/`, header);
    if (response.status === 200) {
      return response.data;
    } else {
      throw Error("An error has occurred");
    }

  } catch (err) {
    toast.error(err.response.message);
    throw err;
  }
}

const getRecruiters = async () => {
  try {
    const header = await token();
    const response = await axios.get(`${url}/recruiters/`, header);
    if (response.status === 200) {
      return response.data;
    } else {
      throw Error("An error has occurred");
    }

  } catch (err) {
    toast.error(err.response.message);
    throw err;
  }
}

const getUsersClient = async (userId) => {
  try {
    const header = await token();
    const response = await axios.get(`${url}/user-profiles?filters[id][$eq]=${userId}&populate=*`, header)
    if (response.status === 200) {
      return response.data;
    } else {
      throw Error("An error has occurred");
    }
  } catch (err) {
    toast.error(err.response.message);
    throw err;
  }
}

export { 
  getUserOwnedProfile,
  getRecruiters,
  getUsersProfile,
  addUserProfile, 
  updateUserProfile, 
  deleteUserProfile,
  getUsersClient
};
